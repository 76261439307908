import {
  AdminFirmBasicInformationRead,
  FirmCategoryType,
  IndustryType,
  LegalEntityType,
} from '@capital-markets-gateway/api-client-rolodex';
import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import {
  apiTypes,
  Banner,
  Panel,
  PrimaryButton,
  SecondaryButton,
  SelectField,
  ServerErrors,
  TextAreaField,
} from '@cmg/common';
import { FormikProps, withFormik } from 'formik';
import React from 'react';

import { UUID } from '../../../../types/common';
import {
  firmCategoryOrganizationsLabels,
  firmCategoryOrganizationsOptions,
} from '../../../../types/domain/entity-matcher/constants';
import { UpdateFirmDetails } from '../../../../types/domain/firm/FirmDetails';
import { RolodexMetadata } from '../../../../types/domain/rolodex/Metadata';
import {
  SButtonWrapper,
  SFieldWrapper,
  StyledInfoLabel,
  StyledInfoValue,
  StyledPanelHeader,
} from './FirmDetailsEditor.styles';

enum DetailsFieldName {
  DESCRIPTION = 'description',
  ENTITY_TYPE = 'entityType',
  INDUSTRY_TYPE = 'industryType',
  FIRM_CATEGORY_TYPE = 'firmCategoryType',
}

export type DetailsPanelValues = {
  description: string;
  entityType: string | null;
  industryType: string | null;
  firmCategoryType: FirmCategoryType | null;
};

type EditingProps =
  | {
      /** Whether or not the "Edit" button is displayed. */
      isEditable?: true;
      /** Whether or not the UI is in edit mode. */
      isEditing?: boolean;
      /** Callback prop that's triggered when the Edit button is clicked. */
      onEditButtonClick?: () => void;
      /** Callback prop that's triggered when the Cancel Editing button is clicked. */
      onCancelEditButtonClick?: () => void;
      /** Callback prop that's triggered when the submit button is clicked. */
      onSubmit?: (updatedDetails: UpdateFirmDetails, detailsId: UUID) => void;
    }
  | {
      /** Whether or not the "Edit" button is displayed. */
      isEditable?: false | undefined;
      /** Whether or not the UI is in edit mode. */
      isEditing?: never;
      /** Callback prop that's triggered when the Edit button is clicked. */
      onEditButtonClick?: never;
      /** Callback prop that's triggered when the Cancel Editing button is clicked. */
      onCancelEditButtonClick?: never;
      /** Callback prop that's triggered when the submit button is clicked. */
      onSubmit?: never;
    };

export type OwnProps = {
  /** The FirmDetails object to display */
  firmDetails: AdminFirmBasicInformationRead | null;
  /** Whether or not the Entity Type field is required */
  isEntityTypeRequired?: boolean;
  /** Whether or not the UI is loading */
  loading?: boolean;
  /** An error object to display details for */
  error?: apiTypes.GenericServerError | null;
  /** A RolodexMetadata object used to populate the Entity Type and Industry Type options */
  metadata: RolodexMetadata;
} & EditingProps;
export type Props = OwnProps & FormikProps<DetailsPanelValues>;

/**
 * Displays Firm Details, and optionally allows editing
 * of those details.
 */
export const FirmDetailsEditorComponent: React.FC<Props> = ({
  isEditable = true,
  isEditing = false,
  firmDetails,
  loading,
  error,
  metadata,
  onEditButtonClick,
  onCancelEditButtonClick,
  handleSubmit,
  resetForm,
}) => {
  const { userPermissions } = useAuth();
  const canEdit = checkPermissions(userPermissions, [permissionsByEntity.Firm.FULL]);
  const detailsFields = [
    {
      id: DetailsFieldName.DESCRIPTION,
      label: 'Description',
      editRenderer: () => (
        <TextAreaField disabled={loading} name={DetailsFieldName.DESCRIPTION} fullWidth rows={3} />
      ),
    },
    {
      id: DetailsFieldName.ENTITY_TYPE,
      label: 'Entity Type',
      cellRenderer: value => {
        const entityType = metadata.entityTypes.find(type => type.value === value);
        return <span>{entityType ? entityType.label : ''}</span>;
      },
      editRenderer: () => (
        <SelectField
          name={DetailsFieldName.ENTITY_TYPE}
          options={metadata.entityTypes}
          disabled={loading}
        />
      ),
    },
    {
      id: DetailsFieldName.INDUSTRY_TYPE,
      label: 'Industry Type',
      cellRenderer: value => {
        const industryType = metadata.industryTypes.find(type => type.value === value);
        return <span>{industryType ? industryType.label : ''}</span>;
      },
      editRenderer: () => (
        <SelectField
          name={DetailsFieldName.INDUSTRY_TYPE}
          options={metadata.industryTypes}
          disabled={loading}
        />
      ),
    },
    {
      id: DetailsFieldName.FIRM_CATEGORY_TYPE,
      label: 'Firm Category',
      cellRenderer: value => {
        const firmCategoryType = firmCategoryOrganizationsLabels[value];
        // const firmCategory = ;
        return <span>{firmCategoryType || ''}</span>;
      },
      editRenderer: () => (
        <SelectField
          name={DetailsFieldName.FIRM_CATEGORY_TYPE}
          options={firmCategoryOrganizationsOptions}
          disabled={
            loading ||
            firmDetails?.firmCategoryType === FirmCategoryType.PWM ||
            firmDetails?.firmCategoryType === FirmCategoryType.FREE_TEXT
          }
        />
      ),
    },
  ];

  return (
    <Panel>
      <StyledPanelHeader
        title="Basic Information"
        rightContent={
          canEdit &&
          isEditable &&
          onEditButtonClick &&
          onCancelEditButtonClick && (
            <React.Fragment>
              {!isEditing ? (
                <SecondaryButton onClick={onEditButtonClick}>Edit</SecondaryButton>
              ) : (
                <SButtonWrapper>
                  <SecondaryButton
                    onClick={() => {
                      resetForm();
                      onCancelEditButtonClick();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton onClick={() => handleSubmit()}>Save</PrimaryButton>
                </SButtonWrapper>
              )}
            </React.Fragment>
          )
        }
      />
      {error && (
        <Banner variant="error" showIcon={false}>
          <ServerErrors error={error} />
        </Banner>
      )}
      {firmDetails &&
        detailsFields.map(field => {
          return (
            <SFieldWrapper key={field.id}>
              <StyledInfoLabel>{field.label}</StyledInfoLabel>
              {isEditing && <StyledInfoValue>{field.editRenderer()}</StyledInfoValue>}
              {!isEditing && (
                <StyledInfoValue>
                  {field.cellRenderer
                    ? field.cellRenderer(firmDetails[field.id])
                    : firmDetails[field.id]}
                </StyledInfoValue>
              )}
            </SFieldWrapper>
          );
        })}
    </Panel>
  );
};

export default withFormik<OwnProps, DetailsPanelValues>({
  validate: (values, props) => {
    if (!values.entityType && props.isEntityTypeRequired) {
      return {
        entityType:
          'This organization currently contains an Investor or Sponsor role type.  You cannot remove the organization Entity Type until these roles are deleted.',
      };
    }
  },
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  isInitialValid: false,
  mapPropsToValues: ({ firmDetails }) => ({
    description: firmDetails && firmDetails.description ? firmDetails.description : '',
    industryType: firmDetails ? firmDetails.industryType : '',
    entityType: firmDetails ? firmDetails.entityType : '',
    firmCategoryType: firmDetails ? firmDetails.firmCategoryType : null,
  }),
  handleSubmit: (values, formikBag) => {
    if (!formikBag.props.onSubmit || !formikBag.props.firmDetails) {
      return;
    }
    const entity: LegalEntityType = LegalEntityType[values.entityType ? values.entityType : ''];
    const industry: IndustryType = IndustryType[values.industryType ? values.industryType : ''];
    const firmType: FirmCategoryType =
      FirmCategoryType[values.firmCategoryType ? values.firmCategoryType : ''];
    const exceptions = [FirmCategoryType.REGULAR, FirmCategoryType.EXTENSION];
    const details: UpdateFirmDetails = {
      description: values.description,
      entityType: entity,
      industryType: industry,
      status: formikBag.props.firmDetails!.status,
      recordStatus: formikBag.props.firmDetails!.recordStatus,
      firmCategoryType: firmType.length > 0 && exceptions.includes(firmType) ? firmType : null,
    };

    formikBag.props.onSubmit(details, formikBag.props.firmDetails.id);
  },
})(FirmDetailsEditorComponent);
